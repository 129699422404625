<template>
  <div class="login__modal active">
    <form>
      <div class="container">
        <div class="wrapp_logo">
          <router-link to="/" class="logo"></router-link>
          <SelectorLang color="#930684" style="height: 42px"/>
        </div>
          <span class="err" v-if="badrequest">{{textError.value}}</span>
          <strong class="title" :style="{ 'color': badrequest ? 'red' : 'black' }">{{$t('login.recoveryPass')}}</strong>
          <div class="inp">
            <input :type="hiddenpass" :class="{'warning': !password.status }" :placeholder="selectLang.newpass" v-model="password.value" @keyup.enter="sendData">
          </div>
          <div class="inp">
            <input :type="hiddenpass" autocomplete="off" :class="{'warning': !password.status }" :placeholder="selectLang.confirmPas" v-model="password2" @keyup.enter="sendData">
          </div>
          <div class="btm">
            <div class="register__btn" @click="gen_password">
              {{$t('login.generate')}}
            </div>
            <button :disabled="dataResetPass.btnBlock" type="button" @click="sendData">
              {{dataResetPass.btnBlock ? 'Loading' : $t('login.save')}}
            </button>
          </div>
      </div>
      <div class="btn_registration" @click="$router.push({name: 'Registration'})">{{ $t('login.registration') }}</div>
    </form>
    <div class="img__cont">
      <div class="close__btn" @click="$router.push({ name: 'Home' })">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0022 9.19992L0.80422 0.00190735L0.00437312 0.801755L9.20238 9.99976L0.00390625 19.1982L0.803753 19.9981L10.0022 10.7996L19.2007 19.9981L20.0006 19.1982L10.8021 9.99976L20.0001 0.801756L19.2002 0.00190886L10.0022 9.19992Z" fill="white"/>
        </svg>
      </div>
      <img src="/img/login-photo.jpg" alt="">
    </div>
  </div>
</template>

<script>
import LangRu from '@/lang/Login/ru' 
import LangRo from '@/lang/Login/ro' 
import LangEng from '@/lang/Login/eng'
import SelectorLang from '@/components/SelectorLang'
import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins:[LangRu, LangRo, LangEng],
  components: {
    SelectorLang
  },
  data: () => ({
    badrequest: false,
    hiddenpass: 'password',
    token: '',
   
    password: {
      value: '',
      status: ' '
    },
    password2: '',
    textError: {
      value: '',
      password: {
        ru: 'Пароль должен состоять из 8-ми символов',
        ro: 'Parola trebuie sa contina 6 simboluri',
        en: 'Password must be 8 characters long'
      },
      mismatch: {
        ro: 'Parola nu se potrivește',
        ru: 'Пароль не совпадает',
        en: 'Password does not match'
      }
    },
    dataResetPass: {
      btnBlock: false
    }
  }),
  async beforeMount() {
    try {
      this.token = this.$route.params.token;     
      const request = await fetch(`${domain}/api/password_reset/validate_token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": `${this.token}`
        })
      });
      if(!request.ok){
        this.$router.push({ name: 'Home', params:{ lang: this.langStorage}});
      }
    }
    catch(er){
      console.error(er);
    }
  },
  methods: {
    async sendData(){
      this.textError.value = this.password.value != this.password2 ? this.textError.mismatch[this.langStorage] : 
      this.password2.length < 8 ? this.textError.password[this.langStorage] : true;

      this.password.status = this.textError.value === true;

      this.badrequest = this.textError.value === true ? false : true;
      
     if(this.textError.value === true) {
      this.dataResetPass.btnBlock = true;
      try{
        //send date api
        let request = await fetch(`${domain}/${this.langStorage}/api/password_reset/confirm/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "token": this.token,
            "password": this.password2
          })
        });
        let resp = await request.json();
       
        if(request.status == 400){
          this.textError.value = resp.password[0];
          this.password.status = false;
          this.badrequest = true;
        }
        else if(request.status == 200){
          this.$router.push({name: 'login', params: {lang: this.langStorage}});
        }
            
      }
      catch(er){
        console.error(er);
      }
      this.dataResetPass.btnBlock = false;
     }
    },
    gen_password(){
      let password = "";
      let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
      for (let i = 0; i < 20; i++){
          password += symbols.charAt(Math.floor(Math.random() * symbols.length));     
      }
      this.password.value = password;
      this.password2 = password;
      this.hiddenpass = 'text';
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    }
  }
}
</script>

<style lang="scss">
  .login__modal form .container .btm button {
    outline: none;
  }
  .check {
    background: url("/img/svg/check.svg") no-repeat white right / 30px !important;
    background-position: 95% !important;
  }
  .cross {
    background: url("/img/svg/cross.svg") no-repeat white right / 30px !important;
    background-position: 95% !important;
  }

  .login__modal .logo {
    background: url("/img/logo2.svg") no-repeat !important;
    width: 150px !important;
    height: 50px !important;
  }

  .login__modal {
    .wrapp_logo {
      margin-top: 10%;
    }
  }
  .login__modal {
    .warning {
      border: 1px solid rgba(255, 0, 0, 0.493) !important;
      background: rgba(255, 0, 0, 0.034) !important;
    }
    .warning-text {
      color: red !important;
    }
    span.err {
      display: block;
      font-size: 12px;
      font-weight: 800;
      padding-left: 10px;
      color: #ff00009e;
    }
    .container {
      width: 100%;
      @media(min-width: 1025px) {
        padding: 96px;
      }
    }
    strong.title {
      display: block;
    }
    .btn_registration {
      width: 100%;
      height: 60px;
      margin-top: auto;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #891E82;
      color: white;
      cursor: pointer;
    }
    @media(max-width: 430px) {
      form {
        padding: 0;
        .container {
          padding: 0 16px;
        }
      }
    }
  }

  @media (max-width: 1385px) and (min-width: 430px) {
    .login__modal {
      height: auto !important;
    }
    .login__modal form {
      padding: 20px !important;
    }
  }
  @media (max-width: 1024px) and (min-width: 430px) {
    .login__modal form .container .btm .register__btn {
      font-size: 17px !important;
    }
    .login__modal form .container .btm button {
      font-size: 14px !important;
    }
  }
</style>